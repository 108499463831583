<template>
  <!-- <Drawer
    :visible.sync="visible"   
    :title="isMobilePreview ? '手机预览' : '网页预览'"  
    width="100%"
    position="bottom"
    :zIndex="3000"
  > -->
  <div>
    <div class="topSelect">
       <i class="iconfont icon-shouji" @click="changeEquipment(1)" :class="index==1?'activeSelectI':''"></i>
       <i class="iconfont icon-dv_pingban" @click="changeEquipment(2)" :class="index==2?'activeSelectI':''"></i>
       <i class="iconfont icon-computer_icon" @click="changeEquipment(3)" :class="index==3?'activeSelectI':''"></i>
    </div>
    <div
    class="announcement-mobile"
    ref="iframeDiv"
    v-if="index==1"
  >
    <div class="newBox" >
      <iframe
        id="bdIframe"
        :src="content"
        frameborder="no"
        border="0"
        marginwidth="0"
        marginheight="0"
        scrolling="yes"
        allowtransparency="yes"
      ></iframe>
    </div>
  </div>

  <div
    class="announcement-mobiletwo"
    ref="iframeDiv"
    v-if="index==2"
  >
    <div class="newBoxtwo" >
      <iframe
        id="bdIframetwo"
        :src="content"
        frameborder="no"
        border="0"
       
        scrolling="yes"
        allowtransparency="yes"
      ></iframe>
    </div>
  </div>
  </div>
  <!-- </Drawer> -->
</template>

<script>
// import Drawer from '@/components/Drawer'
export default {
  props: {
    content: String,
    formRef: {
      type: Object,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    isMobilePreview: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    // Drawer
  },
  data() {
    return {
        index:1
    };
  },
  mounted() {
    // const oIframe = document.getElementById("bdIframe");
    //   const deviceWidth = this.$refs.iframeDiv.offsetWidth;
    //   const deviceHeight = this.$refs.iframeDiv.offsetHight;
    //   console.log("oIframe", oIframe);
    //   oIframe.style.width = deviceWidth - 30 + "px";
    //   // oIframe.style.height = deviceHeight - 100 + "px";
    //   oIframe.style.width = deviceWidth - 30 + "px";
    //   oIframe.style.height = "450px"
    //   oIframe.style.marginTop='20px'
    //   console.log("oIframe", oIframe, deviceWidth, deviceHeight);
  },
  methods: {
    changeEquipment(id){
      //1手机 2平板 3电脑
      //为PC的时候不变色
      if(id==3){
        // console.log("走了这里吗",this.content)
        window.open(this.content)
        return
      }
      this.index=id
    }
  },
};
</script>
<style lang="scss" scoped>
.announcement-preview {
  max-width: 1200px;
  margin: 0 auto;
}
.announcement-mobile {
  width: 250px;
  height: 520px;
  // padding: 80px 20px;
  // background: url("~@/assets/images/Mobilephonebox.png") no-repeat;
  // background: url("~@/assets/images/iphoneX.png") no-repeat;
  background: url("~@/assets/images/phoneBgs.png") no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  display: flex;
  position: relative;
  left: -100px;
  overflow: hidden;
  box-shadow: #666 0px 0px 5px; 
  border-radius: 17px;
}
.newBox {
  position: relative;
  width: 235px;
  height: 410px;
  overflow: hidden;

  margin-top: 60px;
  margin-left: 5px;
}
.announcement-mobiletwo {
  width: 400px;
  height: 520px;
  // padding: 80px 20px;
  // background: url("~@/assets/images/Mobilephonebox.png") no-repeat;
  // background: url("~@/assets/images/iphoneX.png") no-repeat;
  background: url("~@/assets/images/ipadBg.png") no-repeat;
  background-size: 100% 100%;
  
  
  box-sizing: border-box;
  display: flex;

  overflow: hidden;
}
.newBoxtwo {
  position: relative;
  width: 322px;
  height: 415px;
  overflow: hidden;
  margin-top: 50px;
  margin-left: 40px;
}
#bdIframe {
  width: 242px;
  border: none;
  height: 430px;
  margin-left: 5px;
  overflow-x: hidden;
  overflow-y: scroll;
}
#bdIframetwo {
  width: 312px;
  border: none;
  height: 430px;
  margin-left: 5px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.newBox.child::-webkit-scrollbar{
  width: 0;
  height: 0;

}
.topSelect{
  width: 140px;
  font-size: 50px;
  height: 50px;
 display: flex;
 align-items: center;
 justify-content: space-around;
 margin-bottom: 20px;
 position: absolute;
 top: 0;
 left: 20px;
 i{
   font-size: 30px;
   cursor: pointer;
 }
 .activeSelectI{
   color:#F7BD01;
 }
} 

</style>

