<template>
  <el-dialog
    :visible.sync="visible"
    :title="title"
    center
    width="75%"
    append-to-body
  >
    <div class="container">
      <div class="show-flex">
        <div class="show-preview">
          <viw-modal :content="webUrl"></viw-modal>
          <!-- <div class="show-tab">
              <p>上一位</p>
              <p>下一位</p>
            </div> -->
        </div>
        <div class="right">
          <div class="show-right">
            <div class="show-erwei">
              <div class="qrcode" id="qrcode" ref="qrcode"></div>
              手机扫码观看
            </div>
            <div style="flex: 1">
              <p class="show-title">{{ detail.aboutName || "" }}</p>
              <p class="show-subtitle">
                {{ detail.name || "" }}
              </p>
            </div>
          </div>
          <div class="show-recommend">
            <p>推荐浏览</p>
            <div class="show-list">
              <img
                v-for="(item, index) in list"
                :key="index"
                :src="item.coverUrl"
                alt=""
                @click="showPreviewModal(item)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <preview-modal ref="previewModal"></preview-modal>
  </el-dialog>
</template>
<script>
import PreviewModal from "@/components/PreviewModal";
import viwModal from "@/components/viwModal";
import QRCode from "qrcodejs2"; // 引入插件
import {
  selectRandomAtlTime,
  timeDetails,
  isMyself,
  addReadCount,
} from "@/api/index";
// 添加类型分类
export default {
  name: "PreviewModal",
  components: {
    viwModal,
    PreviewModal,
  },
  data() {
    return {
      list: [],
      detail: {
        aboutName: "",
        name: "",
      },
      webUrl: "",
      type: null,
      visible: false,
      title: null,
      loading: false,
    };
  },
  created() {},
  methods: {
    show(data) {
      this.type = data;
      this.visible = true;
      this.loading = false;
      this.init(data);
    },
    init(id) {
      console.log("id", id);
      if (id) {
        this.id = id;
        setTimeout(()=>{
          this.timeDetails();
        this.addReadCount();
        this.getWebUrl();
        this.selectRandomAtlTime();
        },500)
      } else {
        this.$router.go(-1);
      }
    },
    qrcode() {
      console.log("=====web===",this.webUrl)
        new QRCode(this.$refs.qrcode, {
        width: 100, // 设置宽度，单位像素
        height: 100, // 设置高度，单位像素
        colorLight: "#fff",
        text: this.webUrl, // 设置二维码内容或跳转地址
      });
      
      console.log("ddd");
    },
    addReadCount() {
      addReadCount({ id: this.id, type: 1 }).then(() => {});
    },
    timeDetails() {
      timeDetails({ timeId: this.id }).then((res) => {
        this.detail = res.data;
      });
    },
    getWebUrl() {
      isMyself({ id: this.id, type: 0 }).then((res) => {
        if (res.data == true) {
          this.webUrl =
            "https://go.yunzhongci.com/?timerid=" +
            this.id +
            "&token=" +
            this.$store.state.token +
            "&isMy=true";
        } else {
          this.webUrl =
            "https://go.yunzhongci.com/?timerid=" +
            this.id +
            "&token=" +
            this.$store.state.token;
        }
        console.log("webUrl", this.webUrl);
        if (document.getElementById("qrcode").innerHTML) {
          document.getElementById("qrcode").innerHTML = "";
        }
        this.qrcode();
      });
    },
    selectRandomAtlTime() {
      selectRandomAtlTime().then((res) => {
        console.log(res);
        this.list = res.data;
      });
    },
    showPreviewModal(item) {
      console.log(item.id)
      this.$refs.previewModal.show(item.id);
      this.visible = false;
      // if (item.device == 1) {
      //   this.$refs.previewModal.show(item.id);
      // } else {
      //   this.$router.push({
      //     path: "/preview",
      //     query: { id: item.id },
      //   });
      // }
    },
    close() {
      this.$emit("close");
      this.visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.show-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container {
  width: 100%;
  margin: 0 auto;
  padding: 5%;
  background: url("~@/assets/images/Exhibitionbg.png") no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
}
.show-preview {
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .show-tab {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    p {
      writing-mode: tb-rl;
      background: #e9e9e9;
      font-size: 16px;
      color: #999;
      padding: 10px 5px;
    }
    p:first-child {
      margin-bottom: 50px;
    }
  }
}
.right {
  width: 50%;
}
.show-right {
  display: flex;
  .show-erwei {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 3%;
    color: #666;
    font-size: 12px;

    .qrcode {
      width: 120px;
      height: 120px;
      padding: 10px;
      background: #fff;
      margin-bottom: 10px;
    }
  }

  .show-title {
    color: #5d6063;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .show-subtitle {
    width: 70%;
    color: #666666;
    line-height: 36px;
    letter-spacing: 2px;
    font-size: 14px;
  }
}
.show-recommend {
  p {
    color: #333;
    font-size: 20px;
    font-weight: bold;
    margin: 50px 0 20px;
  }
  .show-list {
    display: flex;
    width: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
    img {
      width: 100%;
      width: 150px;
      margin: 10px;
      border-radius: 10px;
      cursor:pointer
    }
  }
}
</style>